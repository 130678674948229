import isFunction from 'lodash.isfunction'
import { Redirect } from './Redirect'
import { storage } from 'src/storage'
import { useRouter } from 'src/hooks/useRouter'
import { useUser } from 'src/hooks/useUser'
import { userMustFinishOnboarding } from 'src/utils/userUtils'
import { isPrePaywallQuizFinished } from 'src/providers/Quiz/utils'

const RedirectIfComponent = ({ children, condition, redirect, returnUrl, userStore }) => {
  const router = useRouter()

  if (userStore.isLoadingInitialState) return null

  if (returnUrl) {
    storage.session.setItem('returnUrl', returnUrl)
  }

  if (condition) {
    return <Redirect to={redirect} />
  }

  return isFunction(children) ? children({ router, userStore }) : children
}

export const RedirectIf = {
  CantCreateSubscription: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: userStore.isGuest || userStore.isMember,
      redirect: userStore.isGuest ? '/login' : '/home',
      userStore,
    })
  },
  CantUpdateSubscription: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: userStore.isGuest || userStore.isNonbillable || userStore.isCanceled,
      redirect: userStore.isGuest ? '/login' : '/',
      userStore,
    })
  },
  CantUseSocialFeatures: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: !userStore.canUseSocialFeatures,
      redirect: userStore.isGuest ? '/login' : userStore.isMember ? '/profile' : '/',
      userStore,
    })
  },
  HasUsername: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: !!userStore?.username,
      redirect:
        (typeof props.redirect === 'function'
          ? props.redirect(userStore?.username)
          : props.redirect) || `/${userStore?.username}`,
      userStore,
    })
  },
  IsGuest: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: userStore.isGuest,
      redirect: props.returnUrl ? '/login' : '/',
      userStore,
    })
  },
  IsNotGuest: (props) => {
    const userStore = useUser()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: !userStore.isGuest,
      redirect: userStore.isMember
        ? props.redirect
          ? props.redirect
          : '/home'
        : props.paywall
        ? '/onboarding/subscription'
        : '/',
      userStore,
    })
  },
  IsMember: (props) => {
    const userStore = useUser()

    // TODO: add PT quiz route when it is created
    const onboardingRedirectRoute = userStore?.isPtActive ? '' : '/onboarding/welcome'

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: userStore.isMember,
      redirect: userMustFinishOnboarding(userStore?.currentUser)
        ? onboardingRedirectRoute
        : '/home',
      userStore,
    })
  },
  HasPrePaywallQuizIncomplete: (props) => {
    const userStore = useUser()
    const finished = isPrePaywallQuizFinished()

    return React.createElement(RedirectIfComponent, {
      ...props,
      condition: !finished,
      redirect: '/obe-quiz',
      userStore,
    })
  },
}
