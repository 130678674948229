'use client'

const cloudflarePrefix = 'cdn-cgi/image/'

export const cloudflareLoader = ({ src, width }) => {
  if (src?.includes(cloudflarePrefix)) {
    const params = ['fit=contain', 'format=auto']
    if (width) {
      params.push(`width=${width}`)
    }
    const paramsString = params.join(',')
    return src.replace(cloudflarePrefix, `${cloudflarePrefix}${paramsString}`)
  }
  return src
}

export const widths = {
  avatar: 128,
  card: 400,
  mobile: 1000,
  desktop: 1200,
  hd: 1920,
  hires: 2900,
}

export const avatarLoader = (src) => cloudflareLoader({ src, width: widths.avatar })
