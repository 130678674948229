import { storage } from 'src/storage'
import { PrePaywallAnswer, PrePaywallStoredAnswersType, QuizQuestion } from 'src/types/Quiz'
import { PlanPreviewCardType, PlanPreviewCardsType } from 'src/types/QuizPreview'

export const phoneDefaultValue = '___ - ___ - ____'

export const codeDefaultValue = '--- ---'

export const parseRouteToCamelCase = (route: string) => {
  const value = route.split('-')
  return value.length > 1 ? value[0] + value[1][0].toUpperCase() + value[1].slice(1) : value
}
// validate E.164 compliant number
export const e164Formatted = (phoneNumber: string, countryCode = '+1') =>
  `${countryCode}${phoneNumber.split('-').join('')}`

export const validatePhoneNumber = (phoneNumber: string) => {
  const formattedNumber = e164Formatted(phoneNumber)
  const regex = /^\+[1-9]\d{10,14}$/

  if (
    !regex.test(formattedNumber) &&
    formattedNumber.length &&
    phoneNumber !== phoneDefaultValue &&
    formattedNumber.length >= 10
  )
    return new Error('Not a valid phone number')
  else if (phoneNumber === phoneDefaultValue) return false
  else return true
}

// eslint-disable-next-line no-unused-vars
export const resultPageRoutes = Object.values(obe.quizzes).reduce(
  (routeMap, { slug, resultsRoute }) => {
    routeMap[slug] = resultsRoute
    return routeMap
  },
  {} as Record<string, string>
)

export const QUESTION_TYPES = {
  TEXT_ONLY: 'text_only',
  WITH_IMAGE: 'with_image',
  FITNESS_LEVEL: 'fitness_level',
  BIRTHDAY: 'birthday',
  FREE_TEXT: 'free_text',
  PHONE_NUMBER: 'phone',
  GENDER: 'gender',
  PHONE_VERIFICATION: 'phone_verification',
}

export const DEFAULT_EMPTY_ANSWER = { value: '', choices: [] }

export const getQuestionUrl = (quizSlug: string, questionSlug: string) => {
  const prefix =
    quizSlug === obe.quizzes.quiz_before_paywall.slug ? '/obe-quiz/' : `/quiz/${quizSlug}/`
  return `${prefix}${questionSlug}`
}

export const submitPrePaywallAnswer = (question: QuizQuestion, answer: PrePaywallAnswer) => {
  const answers = JSON.parse(storage.local.getItem(obe.quizzes.quiz_before_paywall.slug) ?? '{}')
  answers[question.id] = {
    value: answer.value,
    choices: answer.choices.map(({ id, value }) => ({ id, value })),
    question: {
      id: question.id,
      slug: question.slug,
    },
  }
  storage.local.setItem(obe.quizzes.quiz_before_paywall.slug, JSON.stringify(answers))
}

export const resetPrePaywallAnswers = () => {
  storage.local.setItem(obe.quizzes.quiz_before_paywall.slug, '{}')
}

export const getPrePaywallAnswers = (): PrePaywallStoredAnswersType => {
  const answers = JSON.parse(storage.local.getItem(obe.quizzes.quiz_before_paywall.slug) ?? '{}')
  return answers
}

export const PLAN_FOCUS_COPY = {
  STRENGTH: {
    title: 'Strength & Power',
  },
  STRENGTH_PILATES: {
    title: 'Strength & Power + Pilates, Sculpt & Barre',
  },
}

const planCardsCopy: PlanPreviewCardsType = {
  HANDS_ON_SUPPORT: {
    intro: "You're a",
    title: 'Support Seeker',
    subCopy:
      'Not intrinsically wired to form new habits easily, but don’t stress—most people aren’t!',
    description:
      '<p>A rigid workout plan won’t work for you, because you’re likely juggling many responsibilities or hold an all-or-nothing view on fitness.</p><p>You’ll benefit from flexibility and an adaptable plan! obé will tell you exactly what to do but gives you the power to shift your personalized plan to work with you, not against you.</p> <p> For extra support, obé Personal Training can help you thrive.</p> ',
  },
  THRIVE_WITH_SUPPORT: {
    title: 'Accountability Adopter',
    intro: "You're an",
    subCopy: "PSA, you're like most people!",
    description:
      '<p>We’re often told habit formation is as easy as “putting our minds to it,” but most of us need extra guidance to kick-start new routines.</p><p>Know that your personalized obé plan is flexible and adaptable, so you can shift classes around as needed.</p><p>Try out live classes, scheduling workouts on your calendar, and checking obé stats for accountability boosts. </p>',
  },
  NEED_GUIDANCE: {
    title: 'Adaptive Achiever',
    intro: "You're an",
    subCopy: "You're good at forming habits - with some help!",
    description:
      '<p>Initial guidance is key for you to ignite a new routine. Once you’re going, you’re golden!</p><p>Think of your personalized plan as a “movement prescription,” with flexibility to do your own thing when life comes up.</p><p></p>You’ll also thrive with structured obé Programs and Challenges, which teach new skills without the stress of planning workouts. </p>',
  },
  SELF_MOTIVATED: {
    title: 'Motivated Mover',
    intro: "Your're a",
    subCopy: "Congrats, you're a unicorn!",
    description:
      '<p>You have intrinsic motivation and a drive to push yourself, so habit formation comes naturally to you.</p><p>Setting personal goals and tracking your progress helps you thrive, so take advantage of obé Activity Stats to check your milestones.</p><p>Follow your class plan to a tee, pick up new skills with Programs, or go for a Challenge—variety will level up your routine!</p>',
  },
}

export const getObePreviewCopy = () => {
  const answers: PrePaywallStoredAnswersType = getPrePaywallAnswers()

  const copy: { focusCard: { title: string } | null; planCard: PlanPreviewCardType | null } = {
    focusCard: null,
    planCard: null,
  }

  Object.values(answers).map((answer) => {
    if (answer.question.slug === 'class-type') {
      const planFocus = answer.choices
        .map((choice) => choice.value.replaceAll('-', '_'))
        .sort()
        .join('_')
        .toUpperCase()

      if (planFocus.includes('PILATES_SCULPT') && planFocus.includes('BARRE')) {
        copy.focusCard = PLAN_FOCUS_COPY.STRENGTH_PILATES
      } else {
        copy.focusCard = PLAN_FOCUS_COPY.STRENGTH
      }
    } else if (answer.question.slug === 'habit-needs') {
      const planType = answer?.choices[0]?.value?.replaceAll('-', '_').toUpperCase()

      if (planType && planCardsCopy[planType]?.title) {
        copy.planCard = planCardsCopy[planType]
      }
    }
  })

  return copy
}

// slugs of the questions required for the hard-coded preview logic
const REQUIRED_PRE_PAYWALL_SLUGS = [
  'fitness-goals',
  'fitness-journey',
  'class-type',
  'habit-needs',
  'body-literacy',
]

export const isPrePaywallQuizFinished = () => {
  const answers = getPrePaywallAnswers()
  const savedSlugs = Object.values(answers).map((answer) => answer?.question?.slug)
  const allAnswered = REQUIRED_PRE_PAYWALL_SLUGS.every((slug) => savedSlugs.includes(slug))
  return allAnswered
}
