import * as Sentry from '@sentry/nextjs'

export const ExceptionDispatcher = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={(error) => (
      <div
        style={{
          background: 'white',
          margin: '64px auto',
          maxWidth: 320,
          padding: 32,
          textAlign: 'center',
        }}
      >
        <h5>Oops, something went wrong somewhere.</h5>
        <p>{error.message}</p>
      </div>
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
)
