import { cloudflareLoader } from 'src/utils/image'

export const Image = React.memo(
  ({
    alt = 'obé',
    className,
    fadeIn = false,
    fitToHeight,
    height = 1,
    src = '//:0',
    width = 1,
    cdnWidth,
    ...options
  }) => {
    const [isLoaded, setIsLoaded] = React.useState(false)
    const [isError, setIsError] = React.useState(false)

    const box = fitToHeight
      ? {
          paddingTop: fitToHeight,
          width: (width / height) * fitToHeight,
        }
      : {
          paddingTop: height === 'auto' ? 0 : `${(height / width) * 100}%`,
          width: '100%',
        }

    return (
      <div className={className}>
        <Styles.Container style={{ ...box }} {...options}>
          {React.createElement(Styles.Image, {
            alt,
            isLoaded: !fadeIn || isLoaded,
            onError: () => setIsError(true),
            onLoad: () => fadeIn && setIsLoaded(true),
            src: isError ? '/favicon.png' : cloudflareLoader({ src, width: cdnWidth }),
            style: {
              position: height === 'auto' ? 'relative' : 'absolute',
            },
          })}
        </Styles.Container>
      </div>
    )
  }
)

const Styles = {
  Container: styled.div`
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    ${({ circle }) =>
      circle &&
      css`
        border-radius: 50%;
      `}

    ${({ shadow }) =>
      shadow &&
      css`
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      `}
  `,
  Image: styled.img`
    left: 0;
    max-width: 100%;
    top: 0;
    transition: opacity 0.2s ease-out;
    width: 100%;

    ${({ isLoaded }) => css`
      opacity: ${isLoaded ? 1 : 0};
    `}
  `,
}
